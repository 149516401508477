/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import Fade from 'react-reveal/Fade'
/* Imported Components */
import { Context } from '../../../context'
/* Styles */
import styles from './event.module.scss'
/* Component */
const Event = ({ id, event, activeEventId, setActiveEventId }) => {
  const { windowSize } = useContext(Context)
  const [eventClasses, setEventClasses] = useState(styles.event)
  const [overlayHeight, setOverlayHeight] = useState(0)
  const [imageIndex] = useState(Math.floor(Math.random() * event.images.length))

  const setEvent = () => {
    if (activeEventId === undefined || activeEventId !== id) {
      setActiveEventId(id)
    } else {
      setActiveEventId(undefined)
    }
  }

  useEffect(() => {
    if (activeEventId !== undefined) {
      setOverlayHeight(document.getElementById('events-container').offsetHeight)
      setEventClasses(styles.event + ' ' + styles.tall + `${activeEventId === id ? ` ${styles.active}` : ''}`)
    } else {
      setEventClasses(styles.event)
      setOverlayHeight(0)
    }
  }, [activeEventId, windowSize])

  return (
    <div id={`col-${id + 1}`} className={eventClasses} style={{ gridColumn: id + 1 }}>
      <div className={styles.info}>
        {event.title && <Fade bottom><h1 className={styles.title}>{event.title}</h1></Fade>}
        {event.body && event.body.body && <Fade bottom><p className={styles.body}>{event.body.body}</p></Fade>}
        {event.footer && event.footer.footer && <Fade bottom><p className={styles.footer}>{event.footer.footer}</p></Fade>}
      </div>
      <BackgroundImage Tag='div' className={styles.image} fluid={event.images[imageIndex].fluid} onClick={setEvent}>
        <div className={styles.label}>
          <div className={styles.title}><strong>{event.title}</strong></div>
        </div>
        <div className={styles.overlay} style={{ height: `${overlayHeight}px`, transition: 'all 1s' }} />
      </BackgroundImage>
    </div>
  )
}
/* Exports */
export default Event
