/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade'
/* Imported Components */
import { Context } from '../context'
import { Layout } from '../components'
import Event from '../components/events/event/event'
/* Styles */
import styles from './events.module.scss'
/* Component */
const Events = () => {
  const { events, eventsText, menuItems, clearSelections, setClearSelections } = useContext(Context)
  const [activeEventId, setActiveEventId] = useState()
  const [activeEvent, setActiveEvent] = useState()
  const [eventsClasses, setEventsClasses] = useState(styles.events)
  const [eventsGridTemplateColumns, setEventsGridTemplateColumns] = useState(['auto', 'auto', 'auto', 'auto', 'auto'].join(' '))

  useEffect(() => {
    if (clearSelections) {
      setClearSelections(false)
      setActiveEventId(undefined)
    }
  }, [clearSelections])

  useEffect(() => {
    const gridTemplateColumns = ['auto', 'auto', 'auto', 'auto', 'auto']
    if (activeEventId !== undefined) {
      gridTemplateColumns[activeEventId] = '1fr'
    }
    setEventsGridTemplateColumns(gridTemplateColumns.join(' '))
  }, [activeEventId])

  useEffect(() => {
    if (activeEventId !== undefined) {
      setActiveEvent(events[activeEventId])
    } else {
      setActiveEvent(undefined)
    }
  }, [activeEventId])

  useEffect(() => {
    if (activeEventId !== undefined) {
      setEventsClasses(styles.events + ' ' + styles.active)
    } else {
      setEventsClasses(styles.events)
    }
  }, [activeEventId])

  return (
    <Layout title='Events'>
      <section id='events-container' className={eventsClasses}>
        <div className={styles.copy}>
          <div className={styles.container}>
            <h1 className={styles.title}>{menuItems.length && menuItems.find(item => item.link === '/events').title}</h1>
            <div className={styles.copy}>
              {eventsText.split('\n').map((paragraph, i) => paragraph && <p key={i} className={styles.body}>{paragraph}</p>)}
            </div>
          </div>
        </div>
        <div className={styles.sections} style={{ gridTemplateColumns: eventsGridTemplateColumns }}>
          {events.map((event, i) => <Event key={i} activeEventId={activeEventId} event={event} id={i} setActiveEventId={setActiveEventId} />)}
        </div>
      </section>
    </Layout>
  )
}
/* Exports */
export default Events
